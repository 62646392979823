import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ColorSection from "./subcomponent/ColorSection";
import DownloadSection from "./subcomponent/DownloadSection1";
import TagsSection from "./subcomponent/TagsSection";
import "./Viewimage.css";

const Viewimage = () => {
  const { slug } = useParams(); // Use slug instead of name
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [svgimages, setImage] = useState("");
  const [temporarySvgContent, setTemporarySvgContent] = useState("");
  const [modalTitle, setModalTitle] = useState(slug); // Set modalTitle to slug
  const [currentTags, setCurrentTags] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState("svg");
  const [imageTitle, setImageTitle] = useState(slug); // Set imageTitle to slug
  const [imageList, setImageList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    if (!slug) return;
    console.log('Fetched Image Slug:', slug);
    
    const fetchImages = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://the2px.com/wp-json/wp/v2/svg_images?per_page=100`
        );
        const data = await response.json();

        // Find the image by slug
        const currentImage = data.find(
          (item) => item.slug.toLowerCase() === slug.toLowerCase() // Compare slug instead of title
        );

        if (currentImage) {
          const svgResponse = await fetch(currentImage.svg_image_file);
          const svgContent = await svgResponse.text();

          setImage(svgContent);
          setTemporarySvgContent(svgContent);
          setModalTitle(currentImage.title.rendered);
          setImageTitle(currentImage.title.rendered);
          setCurrentTags(
            Array.isArray(currentImage.svg_image_tags)
              ? currentImage.svg_image_tags
              : currentImage.svg_image_tags?.split(",").map((tag) => tag.trim()) || []
          );

          // Set the image list and current index
          setImageList(data);
          const index = data.findIndex(item => item.slug === slug);
          setCurrentIndex(index);
        } else {
          console.error("Image not found with the given slug.");
        }
      } catch (error) {
        console.error("Error fetching images:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, [slug]);

  // Navigate to the previous image
  const handlePrevClick = () => {
    if (currentIndex === null || currentIndex <= 0) return;
    const prevImage = imageList[currentIndex - 1];
    navigate(`/view/${encodeURIComponent(prevImage.slug)}`); // Navigate by slug
  };

  // Navigate to the next image
  const handleNextClick = () => {
    if (currentIndex === null || currentIndex >= imageList.length - 1) return;
    const nextImage = imageList[currentIndex + 1];
    navigate(`/view/${encodeURIComponent(nextImage.slug)}`); // Navigate by slug
  };

  if (loading) {
    return (
      <div className="loading-container d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="text-center">
          <div className="spinner-border custom-spinner" style={{ width: "3rem", height: "3rem" }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <div className="spinner-grow custom-spinner ms-3" style={{ width: "3rem", height: "3rem" }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <p className="loading-text mt-3">Fetching awesome images for you... Please wait! 🚀</p>
      </div>
    );
  }

  return (
    <div className="viewimage-container">
      {currentIndex > 0 && (
        <button className="modal-nav-button-v left" onClick={handlePrevClick}>
          <img className="arrow-icon-v left" src="https://the2px.com/wp-content/uploads/2024/12/right-arrow-next-svgrepo-com.svg" alt="Left Arrow" />
        </button>
      )}

      {currentIndex < imageList.length - 1 && (
        <button className="modal-nav-button-v right" onClick={handleNextClick}>
          <img className="arrow-icon-v right" src="https://the2px.com/wp-content/uploads/2024/12/right-arrow-next-svgrepo-com.svg" alt="Right Arrow" />
        </button>
      )}

      <div className="viewimage-body">
        <div className="image-modal-container-v">
          <div className="image-preview-container" style={{ width: "75%", height: "75%", marginRight: "3%" }}>
            <div
              className="image-preview"
              style={{ height: "auto", overflow: "hidden", backgroundColor, borderRadius: "15px" }}
              dangerouslySetInnerHTML={{ __html: temporarySvgContent }}
            />
          </div>

          <div className="or-spacer"></div>
          <ColorSection
            selectedFormat={selectedFormat}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            svgimages={svgimages}
            onSvgContentUpdate={setTemporarySvgContent}
          />

          <div className="or-spacer"></div>

          <div className="main-con">
            <DownloadSection
              imageTitle={imageTitle}
              svgContent={temporarySvgContent}
              backgroundColor={backgroundColor}
              onFormatChange={setSelectedFormat}
            />
            <div className="separator2"></div>
            <TagsSection currentTags={currentTags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewimage;
