import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import ImageCard from './ImageCard';

const ImageGallery = ({ bgColor, searchInput, setSearchInput }) => {
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const noImageFoundUrl = "https://the2px.com/wp-content/uploads/2024/10/image-not-found-1.svg";
  
  const navigate = useNavigate();
  const location = useLocation();

  // Sync search input with URL query params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('search') || ''; 
    setSearchInput(query);
  }, [location.search, setSearchInput]);

  useEffect(() => {
    console.log("Search Input:", searchInput);
  }, [searchInput]);

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = noImageFoundUrl;

    const fetchImages = async () => {
      try {
        let allImages = [];
        let page = 1;
        let totalPages;

        do {
          const response = await fetch(`https://the2px.com/wp-json/wp/v2/svg_images?per_page=100&page=${page}`);
          if (!response.ok) {
            throw new Error(`Error fetching images: ${response.statusText}`);
          }

          const data = await response.json();
          allImages = allImages.concat(data);
          totalPages = response.headers.get('X-WP-TotalPages');
          page++;
        } while (page <= totalPages);

        const formattedImages = allImages.map(image => ({
          ...image,
          file: image.svg_image_file.startsWith('http') ? image.svg_image_file : `https://the2px.com/${image.svg_image_file}`,
          tags: image.svg_image_tags ? image.svg_image_tags.split(',') : [],
        }));

        setImages(formattedImages);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const searchValue = searchInput.toLowerCase();
    const filtered = images.filter(image => {
      const tags = image.tags ? image.tags.join(' ') : '';
      const title = image.title.rendered ? image.title.rendered.toLowerCase() : '';
      const categories = Array.isArray(image.svg_file_categorie)
        ? image.svg_file_categorie.map(cat => cat.toLowerCase())
        : [];

      return (
        tags.toLowerCase().includes(searchValue) ||
        title.includes(searchValue) ||
        categories.some(category => category.includes(searchValue))
      );
    });

    setFilteredImages(filtered);
  }, [searchInput, images]);


  return (
    <div className="image-gallery container">
      <div className="row">
        {filteredImages.length > 0 ? (
          filteredImages.map((image) => (
            <div className="col-4 mb-4" key={image.id}>
              <ImageCard
                title={image.title.rendered}
                slugs={image.slug}
                description={image.description}
                svgUrl={image.file}
                tags={image.tags}
                backgroundColor={bgColor}
                otherImages={images}
                ids={image.id}
                categories={image.svg_file_categorie}
              />
            </div>
          ))
        ) : (
          searchInput && (
            <div className="col-12 d-flex flex-column align-items-center justify-content-center" style={{ height: '60vh' }}>
              <img
                src={noImageFoundUrl}
                alt="No images found"
                style={{ width: '50%', marginBottom: '15px' }}
              />
              <h5>It seems we can’t find what you’re looking for.</h5>
            </div>
          )
        )}
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  bgColor: PropTypes.string,
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
};

export default ImageGallery;
