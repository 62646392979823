import React, { useState, useEffect } from "react";
import ColorPickerb from "./ColorPickerb";
import ColorPicker from "./ColorPicker";

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;

  return (...args) => {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func(...args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const ColorSection = ({ selectedFormat, backgroundColor, setBackgroundColor, svgimages, onSvgContentUpdate }) => {
  const [showBgColorPicker, setShowBgColorPicker] = useState(false);
  const [colors, setColors] = useState([]);
  const [temporarySvgContent, setTemporarySvgContent] = useState(svgimages);
  const [currentColorIndex, setCurrentColorIndex] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showMoreColors, setShowMoreColors] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [rotated, setRotated] = useState(false);
  const visibleCount = 5; // Number of visible colors

  useEffect(() => {
    setColors(extractColors(svgimages));
    setTemporarySvgContent(svgimages);
  }, [svgimages]);

  const handleColorChange = (newColor) => {
    if (currentColorIndex !== null) {
      updateColor(currentColorIndex, newColor.hex);
      setImageChanged(true);
    }
  };

  const handleColorCircleClick = (index) => {
    setCurrentColorIndex(index);
    setShowColorPicker(true);
  };

  const extractColors = (svgString) => {
    const colorRegex = /#([0-9A-Fa-f]{3,6})\b/g;
    const foundColors = new Set(svgString.match(colorRegex));

    // Colors to ignore
    const ignoredColors = new Set(["#322D41", "#FAC89B", "#D7DCDC", "#E6EBF0"]);

    // Filter out the ignored colors
    return Array.from(foundColors).filter(color => !ignoredColors.has(color.toUpperCase())).sort();
};

  const updateColor = throttle((index, newColor) => {
    const updatedColors = [...colors];
    updatedColors[index] = newColor;
    setColors(updatedColors);
    const oldColor = colors[index];
    const updatedSvg = temporarySvgContent.replace(new RegExp(oldColor, "g"), newColor);
    setTemporarySvgContent(updatedSvg);
    onSvgContentUpdate(updatedSvg);
  }, 50);

  const resetChanges = () => {
    setColors(extractColors(svgimages));
    setTemporarySvgContent(svgimages);
    onSvgContentUpdate(svgimages);
    setBackgroundColor("transparent");
    setCurrentColorIndex(null);
    setRotated(true);

    setTimeout(() => {
      setRotated(false);
      setImageChanged(true);

      setTimeout(() => {
        setImageChanged(false);
      }, 500);
    }, 600);
  };

  return (
    <div className="content-section">
      <h3>Color's</h3>
      <div className="bg-col">
        <div className="color-picker-row">
          <div
            className="bg-color-circle"
            style={{
              backgroundColor: selectedFormat !== "jpeg" && backgroundColor === "transparent" ? "none" : backgroundColor,
            }}
            onClick={() => setShowBgColorPicker(!showBgColorPicker) }
          >
            {selectedFormat !== "jpeg" && backgroundColor === "transparent" && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  backgroundImage:
                    "linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc)",
                  backgroundSize: "10px 10px",
                  backgroundPosition: "0 0, 5px 5px",
                }}
              />
            )}
          </div>

          {showBgColorPicker && (
            <div className="color-picker-wrapper" style={{ position: "absolute", marginTop: "40px", transform: "translateX(-50%)", zIndex: 1 }}>
              <ColorPickerb
                color={backgroundColor === "transparent" ? "#ffffff" : backgroundColor}
                onChange={(color) => {
                  setBackgroundColor(color.hex);
                  setImageChanged(true);
                }}
                onClose={() => setShowBgColorPicker(false)}
              />

            </div>
          )}

          <div className="separator"></div>

          <div className="colors">
            {colors.length > 0 ? (
              <>
                {colors.slice(0, showMoreColors ? colors.length : visibleCount).map((color, index) => (
                  <div key={index} className="color-circle-wrapper" style={{ position: "relative" }}>
                    <div className="color-circle" style={{ backgroundColor: color }} onClick={() => handleColorCircleClick(index)} />
                    {showColorPicker && currentColorIndex === index && (
                      <div className="color-picker-wrapper" style={{ position: "absolute", transform: "translateX(-50%)", zIndex: 1 }}>
                        <ColorPicker
                          color={colors[currentColorIndex]}
                          onChange={handleColorChange}
                          onClose={() => {
                            setShowColorPicker(false);
                            setCurrentColorIndex(null);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {colors.length > visibleCount && (
                  <button
                    className="edit-more-colors"
                    onClick={() => setShowMoreColors(!showMoreColors)}
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      marginTop: "7px",
                    }}
                  >
                    {showMoreColors ? "Show Less" : "Edit More Colors"}
                  </button>
                )}
              </>
            ) : (
              <div>No colors available</div>
            )}
          </div>

          {imageChanged && (
            <div className="reset-div">
              <button className="reset-btn" onClick={resetChanges}>
                <img src="https://the2px.com/wp-content/uploads/2024/11/reset-svgrepo-com.svg" alt="Reset" style={{ width: "20px", height: "20px" }} className={rotated ? "rotate-img" : ""} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ColorSection;
