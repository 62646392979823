import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ColorSection from './modalcomponent/ColorSection';
import DownloadSection from './modalcomponent/DownloadSection1';
import TagsSection from './modalcomponent/TagsSection';
import './ImageModal.css';

const ImageModal = ({ show, handleClose, image, title, tags, otherImages }) => {
  const [backgroundColor, setBackgroundColor] = useState('transparent');  // Default to transparent
  const [svgimages, setImage] = useState('');
  const [temporarySvgContent, setTemporarySvgContent] = useState(svgimages);
  const [modalTitle, setModalTitle] = useState(title);
  const [currentTags, setCurrentTags] = useState('');
  const [selectedFormat, setSelectedFormat] = useState('svg');
  const [imageTitle, setImageTitle] = useState(title);
  const [currentSlug, setCurrentSlug] = useState(''); // Store the current image slug

  // Update the modal content when the image changes
  const updateModalContent = async (index) => {
    const nextImage = otherImages[index];
    setModalTitle(nextImage.svg_image_name);
    setImageTitle(nextImage.svg_image_name);
    setCurrentTags(nextImage.tags);
    setCurrentSlug(nextImage.slug);  // Update the current slug

    window.history.pushState(null, '', `/view/${encodeURIComponent(nextImage.slug)}`);

    if (nextImage.svg_image_file) {
      try {
        const response = await fetch(nextImage.svg_image_file);
        if (!response.ok) {
          throw new Error(`Failed to fetch SVG: ${response.statusText}`);
        }
        const content = await response.text();
        setTemporarySvgContent(content);
        setImage(content);
      } catch (error) {
        console.error('Error fetching SVG:', error.message);
      }
    }
  };

  // Find the index of the current image based on the slug
  const getCurrentIndex = () => {
    return otherImages.findIndex((image) => image.slug === currentSlug);
  };

  // Handle the left arrow click
  const handleLeftClick = () => {
    const currentIndex = getCurrentIndex();
    const previousIndex = currentIndex > 0 ? currentIndex - 1 : otherImages.length - 1;
    updateModalContent(previousIndex); // Load previous image
    console.log('current index:', currentIndex)
    console.log('previous index:', previousIndex)
  };

  // Handle the right arrow click
  const handleRightClick = () => {
    const currentIndex = getCurrentIndex();
    const nextIndex = currentIndex < otherImages.length - 1 ? currentIndex + 1 : 0;
    updateModalContent(nextIndex); // Load next image
  };

  useEffect(() => {
    if (image) {
      setTemporarySvgContent(image);
      setImage(image);
    }
    if (title) {
      setModalTitle(title);
    }
    if (tags) {
      setCurrentTags(tags);
    }
    if (show) {
      setTemporarySvgContent(image);
    }
  }, [show, image, title, tags]);

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <div className="modal-content1">
        {/* Left Button */}
        <button className="modal-nav-button left" onClick={handleLeftClick}>
          <img
            className="arrow-icon left"
            src="https://the2px.com/wp-content/uploads/2024/12/right-arrow-next-svgrepo-com.svg"
            alt="Left Arrow"
          />
        </button>

        {/* Right Button */}
        <button className="modal-nav-button right" onClick={handleRightClick}>
          <img
            className="arrow-icon right"
            src="https://the2px.com/wp-content/uploads/2024/12/right-arrow-next-svgrepo-com.svg"
            alt="Right Arrow"
          />
        </button>

        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle &&
              modalTitle
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="image-modal-container">
            <div className="image-preview-container" style={{ width: '75%', height: '75%', marginRight: '3%' }}>
              <div
                className="image-preview"
                style={{ height: 'auto', overflow: 'hidden', backgroundColor, borderRadius: '15px' }}
                dangerouslySetInnerHTML={{ __html: temporarySvgContent }}
              />
            </div>

            <div className="or-spacer"></div>
            <ColorSection
              selectedFormat={selectedFormat}
              backgroundColor={backgroundColor}
              setBackgroundColor={setBackgroundColor}
              svgimages={svgimages}
              onSvgContentUpdate={setTemporarySvgContent} // Add this line to handle the updated SVG
            />

            <div className="or-spacer"></div>

            <div className="main-con">
              <DownloadSection
                imageTitle={imageTitle}
                svgContent={temporarySvgContent}
                backgroundColor={backgroundColor}
                onformatchange={setSelectedFormat}
              />
              <div className="separator2"></div>
              <TagsSection currentTags={currentTags} handleClose={handleClose} />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

ImageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImageModal;
