import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming React Router is used

const TagsSection = ({ currentTags = []}) => {
  const navigate = useNavigate();
  const [maxTags, setMaxTags] = useState(4); // Default max tags
  const [showAllTags, setShowAllTags] = useState(false);

  // Function to update max tags based on screen width
  const updateMaxTags = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 500) {
      setMaxTags(3);
    } else if (screenWidth <= 1000) {
      setMaxTags(4);
    } else if (screenWidth <= 1200) {
      setMaxTags(5);
    } else {
      setMaxTags(7);
    }
  };

  useEffect(() => {
    
    updateMaxTags(); // Set initial max tags
    window.addEventListener('resize', updateMaxTags);

    return () => {
      window.removeEventListener('resize', updateMaxTags);
    };
  }, []);

  
  // Render tags dynamically
  const renderTags = () => {
    if (!currentTags || !Array.isArray(currentTags)) return null;
    // Sort tags alphabetically
    const sortedTags = [...currentTags].sort((a, b) =>
      a.trim().localeCompare(b.trim())
    );

    const tagsToDisplay = showAllTags ? sortedTags : sortedTags.slice(0, maxTags);

    return (
      <>
        {tagsToDisplay.map((tag, index) => (
          <li key={index} className="tag">
            <a
              href={`#${tag.trim()}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/search?search=${encodeURIComponent(tag)}`);
              }}
              className="tag-link"
            >
              {tag.trim()}
            </a>
          </li>
        ))}
        {sortedTags.length > maxTags && !showAllTags && (
          <li className="see-more">
            <a
              href="#tags-section"
              onClick={(e) => {
                e.preventDefault();
                setShowAllTags(true);
              }}
              className="see-more-btn"
            >
              See More
            </a>
          </li>
        )}
        {showAllTags && sortedTags.length > maxTags && (
          <li className="see-more">
            <a
              href="#tags-section"
              onClick={(e) => {
                e.preventDefault();
                setShowAllTags(false);
              }}
              className="see-more-btn"
            >
              See Less
            </a>
          </li>
        )}
      </>
    );
  };

  return (
    <div className="tag-sec">
      <h3>Tags</h3>
      <div className="tags">
        <ul className="tags-container">{renderTags()}</ul>
      </div>
    </div>
  );
};

export default TagsSection;
