import React, { useState } from 'react';

const DownloadSection = ({ imageTitle, svgContent, backgroundColor, onformatchange }) => {
  const [selectedFormat, setSelectedFormat] = useState('svg');
  const [resolution, setResolution] = useState('original');
  const [customWidth, setCustomWidth] = useState('');
  const [customHeight, setCustomHeight] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  // Format date as YYYYMMDD without dashes
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  // Get download file name in the required format
  const getDownloadFileName = (format) => {
    const date = getFormattedDate();
    return `the2px-${imageTitle}-${date}.${format}`;
  };

  const scaleSvg = (svgContent, resolution, customWidth = null, customHeight = null) => {
    const svgDoc = new DOMParser().parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc.querySelector('svg');

    if (svgElement) {
      if (resolution === 'custom' && customWidth && customHeight) {
        svgElement.setAttribute('width', customWidth);
        svgElement.setAttribute('height', customHeight);
      } else if (resolution === '500') {
        svgElement.setAttribute('width', '500');
        svgElement.setAttribute('height', '500');
      } else if (resolution === '1000') {
        svgElement.setAttribute('width', '1000');
        svgElement.setAttribute('height', '1000');
      } else if (resolution === '2000') {
        svgElement.setAttribute('width', '2000');
        svgElement.setAttribute('height', '2000');
      } else if (resolution === 'original') {
        svgElement.removeAttribute('width');
        svgElement.removeAttribute('height');
      }
    }

    return new XMLSerializer().serializeToString(svgDoc);
  };

  const handleDownload = () => {
    try {
      setIsDownloading(true);

      if (selectedFormat === 'svg') {
        downloadSvg();
      } else if (selectedFormat === 'png') {
        convertSvgToPng();
      } else if (selectedFormat === 'jpeg') {
        convertSvgToJpeg();
      }

      // Timeout to revert download state
      setTimeout(() => setIsDownloading(false), 2000);
    } catch (error) {
      console.error('Download error:', error);
      setIsDownloading(false); // Reset state in case of failure
    }
  };

  const downloadSvg = () => {
    const websiteComment = '<!-- Downloaded from the2px.com -->\n';
    const updatedSvgContent = scaleSvg(svgContent, resolution, customWidth, customHeight);

    const xmlDeclarationMatch = updatedSvgContent.match(/^<\?xml.*?\?>\s*/);
    let finalSvgContent;

    if (xmlDeclarationMatch) {
      const xmlDeclaration = xmlDeclarationMatch[0];
      finalSvgContent = xmlDeclaration + websiteComment + updatedSvgContent.slice(xmlDeclaration.length);
    } else {
      finalSvgContent = websiteComment + updatedSvgContent;
    }

    const svgBlob = new Blob([finalSvgContent], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', getDownloadFileName('svg'));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const convertSvgToPng = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = () => {
      let width, height;

      if (resolution === 'original') {
        const svgElement = new DOMParser().parseFromString(svgContent, 'image/svg+xml').documentElement;
        width = svgElement.getAttribute('width') ? parseInt(svgElement.getAttribute('width'), 10) : 500;
        height = svgElement.getAttribute('height') ? parseInt(svgElement.getAttribute('height'), 10) : 500;
      } else if (resolution === 'custom' && customWidth && customHeight) {
        width = customWidth;
        height = customHeight;
      } else {
        const size = parseInt(resolution, 10);
        width = height = size;
      }

      canvas.width = width;
      canvas.height = height;

      if (backgroundColor !== 'transparent' && selectedFormat === 'png') {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, width, height);
      }

      const aspectRatio = img.width / img.height;

      if (aspectRatio > 1) {
        ctx.drawImage(img, 0, (height - (width / aspectRatio)) / 2, width, width / aspectRatio);
      } else {
        ctx.drawImage(img, (width - (height * aspectRatio)) / 2, 0, height * aspectRatio, height);
      }

      const pngUrl = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = pngUrl;
      a.download = getDownloadFileName('png');
      a.click();
    };

    img.onerror = () => {
      console.error('Image failed to load for PNG conversion.');
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgContent)}`;
  };

  const convertSvgToJpeg = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.onload = () => {
      let width, height;

      if (resolution === 'original') {
        const svgElement = new DOMParser().parseFromString(svgContent, 'image/svg+xml').documentElement;
        width = svgElement.getAttribute('width') ? parseInt(svgElement.getAttribute('width'), 10) : 500;
        height = svgElement.getAttribute('height') ? parseInt(svgElement.getAttribute('height'), 10) : 500;
      } else if (resolution === 'custom' && customWidth && customHeight) {
        width = customWidth;
        height = customHeight;
      } else {
        const size = parseInt(resolution, 10);
        width = height = size;
      }

      canvas.width = width;
      canvas.height = height;

      const effectiveBackgroundColor = backgroundColor === 'transparent' ? 'white' : backgroundColor;
      ctx.fillStyle = effectiveBackgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const aspectRatio = img.width / img.height;

      if (aspectRatio > 1) {
        ctx.drawImage(img, 0, (height - (width / aspectRatio)) / 2, width, width / aspectRatio);
      } else {
        ctx.drawImage(img, (width - (height * aspectRatio)) / 2, 0, height * aspectRatio, height);
      }

      const jpegUrl = canvas.toDataURL('image/jpeg', 1.0);
      const a = document.createElement('a');
      a.href = jpegUrl;
      a.download = getDownloadFileName('jpeg');
      a.click();
    };

    img.onerror = () => {
      console.error('Image failed to load for JPEG conversion.');
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgContent)}`;
  };


  const handleCopyLink = () => {
    const link = `http://localhost:3000/view${imageTitle}`;
    navigator.clipboard.writeText(link).then(() => {
      alert("Image link copied to clipboard!");
    });
  };



  return (
      <div className="selection-container">
        <div className="format-selection">
          <h3 style={{ paddingLeft: '5%' }}>
            <span>Format</span>
          </h3>
          <div className="b-35">
            <ul className="b-35l">
              <li>
                <a
                  href="#svg"
                  className={`button-35 ${selectedFormat === 'svg' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFormat('svg');
                    onformatchange('svg');
                  }}
                >
                  SVG
                </a>
              </li>
              <li>
                <a
                  href="#png"
                  className={`button-35 ${selectedFormat === 'png' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFormat('png');
                    onformatchange('png');
                  }}
                >
                  PNG
                </a>
              </li>
              <li>
                <a
                  href="#jpeg"
                  className={`button-35 ${selectedFormat === 'jpeg' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFormat('jpeg');
                    onformatchange('jpeg');
                  }}
                >
                  JPEG
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="res-sec">
          <h3 style={{ paddingLeft: '5%' }}>
            <span>Resolution</span>
          </h3>
          <div className="b-35">
            <ul className="b-35l">
              <li>
                <a
                  href="#1000"
                  className={`button-35 ${resolution === '1000' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setResolution('1000');
                  }}
                >
                  1000px
                </a>
              </li>
              <li>
                <a
                  href="#2000"
                  className={`button-35 ${resolution === '2000' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setResolution('2000');
                  }}
                >
                  2000px
                </a>
              </li>
              <li>
                <a
                  href="#original"
                  className={`button-35 ${resolution === 'original' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setResolution('original');
                  }}
                >
                  Original
                </a>
              </li>
              <li>
                <a
                  href="#custom"
                  className={`button-35 ${resolution === 'custom' ? 'active' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setResolution('custom');
                  }}
                >
                  Custom
                </a>
              </li>
            </ul>
          </div>

          {resolution === 'custom' && (
            <div className="custom-width-height">
              <input
                type="number"
                placeholder="Width"
                value={customWidth}
                onChange={(e) => setCustomWidth(e.target.value)}
              />
              <input
                type="number"
                placeholder="Height"
                value={customHeight}
                onChange={(e) => setCustomHeight(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="download-section">
                  <button className="button-29" onClick={handleDownload}>
                    Download
                    <img
                      src={
                        isDownloading
                          ? 'https://the2px.com/the2px/wp-content/uploads/2024/10/save-1.svg'
                          : 'https://the2px.com/wp-content/uploads/2024/10/download-svgrepo-com.svg'
                      }
                      alt={isDownloading ? 'Save Icon' : 'Download Icon'}
                      style={{
                        marginLeft: '10px',
                        height: '20px',
                        width: '20px',
                        filter: 'invert(100%)',
                      }}
                    />
                  </button>
                  <button className="button-30" onClick={handleCopyLink}>

                    <img
                      src={ 'https://the2px.com/wp-content/uploads/2025/02/copy-link-svgrepo-com.svg'
                      }
                      alt={'Share Icon'}
                      style={{
                        height: '20px',
                        width: '20px',
                        filter: 'invert(100%)',
                      }}
                    />
                  </button>
                </div>
      </div>
  );
};

export default DownloadSection;
