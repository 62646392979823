import React, { useState } from "react";
import Navbar from "./components/navbar";
import ImageGallery from "./components/ImageGallery";
import Footer from "./components/Footer";
import Tool from "./components/tools/Tool"; // Tool component for tools page
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Viewimage from "./components/viewimage/Viewimage";
import "./App.css";

const App = () => {
  const [searchInput, setSearchInput] = useState(''); // State for search input
  const location = useLocation();

  return (
    <div className="app">
      {/* Conditionally render Navbar only if not on /tool route */}
      {location.pathname !== "/tool" && (
        <Navbar setSearchInput={setSearchInput} />
      )}
      <main className="content">
        <Routes>
          {/* Main Image Gallery Route */}
          <Route path="/" element={<ImageGallery searchInput={searchInput} setSearchInput={setSearchInput} />} />
          <Route path="/search" element={<ImageGallery searchInput={searchInput} setSearchInput={setSearchInput} />} />
          <Route path="/tool" element={<Tool />} />
          <Route path="/view/:slug" element={<Viewimage />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
};

export default App;
