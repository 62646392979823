import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './navbar.css';

const Navbar = ({ setSearchInput }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const handleLogoClick = () => {
    setSearchInput('');
    setSearchParams({});
    navigate('/');
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchInput(query);

    if (query) {
      setSearchParams({ search: query });
      navigate(`/search?search=${query}`);
    } else {
      setSearchParams({});
      navigate('/');
    }
  };

  useEffect(() => {
    const query = searchParams.get('search') || '';
    setSearchInput(query);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [searchParams, setSearchInput]);

  return (
    <nav className={`navbar navbar-light ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid" id="org-nav">
        <div className="navbar-brand" onClick={handleLogoClick}>
          <a href="/" rel="noopener noreferrer" className="logo-link">
            <img src="https://the2px.com/wp-content/uploads/2024/10/the2px-logo.svg" alt="the2px logo" className="navbar-logo" />
          </a>
        </div>
        <div className="r-div d-flex align-items-center">
          <div className="search-bar mx-3">
            <input
              type="text"
              placeholder="Search by tags..."
              className="form-control"
              value={searchParams.get('search') || ''}
              onChange={handleSearchChange}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
            />
            <div className='s-icn'>
              <span className="search-icon">
                {isInputFocused ? (
                  <img src="https://the2px.com/wp-content/uploads/2024/10/right-arrow-svgrepo-com.svg" alt="Arrow Icon" width="20" height="20" className="inverted-arrow yellow-icon" />
                ) : (
                  <img src="https://the2px.com/wp-content/uploads/2024/10/search-svgrepo-com.svg" alt="Search Icon" width="20" height="20" className="yellow-icon" />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
